import React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className="bg-footer-blue">
      <nav className="flex items-center justify-between max-w-4xl p-4 mx-auto text-sm">


        <ul className="flex flex-row justify-between mb-0 py-4">
          <li className="border-r-2 border-footer-text px-3 text-footer-text font-normal">
            <Link to="/imprint">
              Impressum
            </Link>
          </li>
          <li className="border-r-2 border-footer-text px-3 text-footer-text font-normal">
            <Link to="/privacy">
              Datenschutz
            </Link>
          </li>
          <li className="px-3  text-footer-text font-normal">
            <Link to="/agb">
              AGB
            </Link>
          </li>
        </ul>

      </nav>
    </footer>
  )
}

export default Footer;
